import React from "react"

import SEO from "../../../components/seo"
import Header from "../../../components/header"
import InfoModal from "../../../components/info-modal"

import { ImagePanorama, Viewer, Infospot } from "panolens"
import { navigate } from "gatsby"
import LoadingBar from "../../../components/loading-bar"
import PanoContainer from "../../../components/pano-container"
import { Howl } from "howler"
import QuestionModal from "../../../components/question-modal"
import ToolBar from "../../../components/tool-bar"
import { Vector3 } from "three"

// Custom Icons
import QuestionMark from "../../../../static/icons/question-mark-white.png"
import ArrowMark from "../../../../static/icons/arrow-mark-white.png"
import EmergencyMark from "../../../../static/icons/emergency-mark-white.png"

export default class Aeging extends React.Component {
  constructor() {
    super()
    this.state = {
      currentQuestion: "",
      infoModalIsOpen: false,
      questionModalIsOpen: false,
      progress: 0,
    }
  }

  setPositionToTarget(target, viewer) {
    const { x, y, z } = target.normalize()
    viewer.camera.position.set(x, -y, -z)
  }

  createQuestionInfoSpot(question, position) {
    const questionInfoSpot = new Infospot(300, QuestionMark, false)
    questionInfoSpot.position.set(position.x, position.y, position.z)
    questionInfoSpot.addHoverText(question)
    questionInfoSpot.addEventListener("click", () => {
      this.setState({
        currentQuestion: question,
        questionModalIsOpen: true,
      })
    })

    return questionInfoSpot
  }

  componentDidMount() {
    // Creating the different Panorama objects
    const mainPanorama = new ImagePanorama("/departments/ageing/panorama.png")
    const leftPanorama = new ImagePanorama(
      "/departments/ageing/left-panorama.png"
    )
    const rightPanorama = new ImagePanorama(
      "/departments/ageing/right-panorama.png"
    )

    // Progress Events
    mainPanorama.addEventListener("progress", progressEvent => {
      this.loadingProgress(progressEvent)
    })

    leftPanorama.addEventListener("progress", progressEvent => {
      this.loadingProgress(progressEvent)
    })

    rightPanorama.addEventListener("progress", progressEvent => {
      this.loadingProgress(progressEvent)
    })

    mainPanorama.setLinkingImage(ArrowMark, 350)
    leftPanorama.setLinkingImage(ArrowMark, 350)
    rightPanorama.setLinkingImage(ArrowMark, 350)

    // Linking Panorama's
    mainPanorama.link(leftPanorama, new Vector3(2716.25, 86.68, 4185.64))
    leftPanorama.link(mainPanorama, new Vector3(-2691.85, -3009.17, -2942.72))

    mainPanorama.link(rightPanorama, new Vector3(-687.69, 744.54, -4888.73))
    rightPanorama.link(mainPanorama, new Vector3(1395.81, -3278.65, 3500.09))

    // Create the InfoSpot to Go to the next department
    const doorInfoSpot = new Infospot(350, ArrowMark, false)
    doorInfoSpot.position.set(-3763.6, -234.37, 3271.92)
    doorInfoSpot.addHoverText("Go to the next department")
    doorInfoSpot.addEventListener("click", () => {
      navigate("/departments/anti-social-distance-party/")
    })

    const emergencyExitSpot = new Infospot(350, EmergencyMark, false)
    emergencyExitSpot.position.set(-802.9, 2074.66, 4468.25)
    emergencyExitSpot.addHoverText("Go to the emergency exit")
    emergencyExitSpot.addEventListener("click", () => {
      navigate("/departments/the-emergency-exit/")
    })

    // Question Marks
    const ageQuestion = this.createQuestionInfoSpot(
      "Am I my age?",
      new Vector3(4922.31, -685.27, -520.49)
    )

    const ageImportantQuestion = this.createQuestionInfoSpot(
      "'Age' is an important and useful parameter in society",
      new Vector3(-2732.76, -2922.16, 2995.83)
    )

    const ageSecretQuestion = this.createQuestionInfoSpot(
      "My age is a secret",
      new Vector3(2264.82, -4281.03, -1231.64)
    )

    // Add the InfoSpots to the Main Panorama
    mainPanorama.add(doorInfoSpot)
    mainPanorama.add(emergencyExitSpot)
    mainPanorama.add(ageQuestion)
    mainPanorama.add(ageImportantQuestion)
    mainPanorama.add(ageSecretQuestion)

    const personalGoalsQuestion = this.createQuestionInfoSpot(
      "A new age is always a new deadline for personal goals",
      new Vector3(4653.55, 781.73, 1638.71)
    )

    const countingUpQuestion = this.createQuestionInfoSpot(
      "I am counting up",
      new Vector3(-105.08, -3156.66, -3867.93)
    )

    const yearsRunningQuestion = this.createQuestionInfoSpot(
      "My years are running",
      new Vector3(541.28, 420.87, -4950.65)
    )

    leftPanorama.add(personalGoalsQuestion)
    leftPanorama.add(countingUpQuestion)
    leftPanorama.add(yearsRunningQuestion)

    const oldAgeQuestion = this.createQuestionInfoSpot(
      "Old age is a form of wealth",
      new Vector3(-3107.17, -3668.59, 1356.57)
    )

    const countingDownQuestion = this.createQuestionInfoSpot(
      "I am counting down",
      new Vector3(-2243.04, -4236.17, -1410.37)
    )

    rightPanorama.add(oldAgeQuestion)
    rightPanorama.add(countingDownQuestion)

    const viewer = new Viewer({
      cameraFov: 90,
      container: document.getElementById("pano-container"),
      controlBar: false,
      output: "console",
      autoHideInfospot: false,
    })

    // Set initial Camera position
    this.setPositionToTarget(new Vector3(4982.35, 66.95, -286.52), viewer)

    viewer.add(mainPanorama)
    viewer.add(leftPanorama)
    viewer.add(rightPanorama)

    // Setup the new Howl.
    this.sound = new Howl({
      src: ["/departments/ageing/ageing.mp3"],
    })

    // Play the sound.
    this.sound.play()

    // Fade In
    this.sound.fade(0.0, 0.5, 2000)
  }

  componentWillUnmount() {
    this.sound.fade(0.5, 0.0, 1000)
  }

  loadingProgress(progressEvent) {
    var loaded = progressEvent.progress.loaded
    var total = progressEvent.progress.total
    var completedProgress = 365
    var percentage = Math.round((loaded / total) * completedProgress)

    this.setState({
      progress: percentage,
    })
  }

  closeInfoModal = () => {
    this.setState({
      infoModalIsOpen: false,
    })
  }

  closeQuestionModal = () => {
    this.setState({
      questionModalIsOpen: false,
    })
  }

  render() {
    return (
      <>
        <SEO title="Ageing" />
        <Header menuIsVisible={false} />
        <PanoContainer />
        <ToolBar
          onInfoClick={() =>
            this.setState({
              infoModalIsOpen: true,
            })
          }
          muteSound={() => this.sound.mute(!this.sound.mute())}
        />
        <LoadingBar progress={this.state.progress} />
        <InfoModal
          isOpen={this.state.infoModalIsOpen}
          onRequestClose={this.closeInfoModal}
          title="Ageing"
        >
          <p>
            And how do you feel about ageing? As a kid ‘growing older’ was
            pretty cool. But at some point ‘growing up’ makes life more boring.
            And ‘old age’? Many people would rather not buy it, however if you
            can’t afford it, it changes into a luxury. (Why) is old age measured
            in years and years part of our identity? And does it actually make
            more sense to count up or to count down?
          </p>
        </InfoModal>
        <QuestionModal
          isOpen={this.state.questionModalIsOpen}
          onRequestClose={this.closeQuestionModal}
          question={this.state.currentQuestion}
        ></QuestionModal>
      </>
    )
  }
}
